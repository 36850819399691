<template>
  <div>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="2">
          <el-button type="primary" @click="addInfo">添加品种信息</el-button>
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table :data="tableData" style="width: 100%" @row-dblclick="edit">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="经营单位名称" prop="j_jydwmc"></el-table-column>
        <el-table-column label="运输单位" prop="j_ysdw"> </el-table-column>
        <el-table-column label="起运地" prop="j_qyd"></el-table-column>
        <el-table-column label="目的地" prop="j_mdd"></el-table-column>
        <el-table-column label="运输日期" prop="j_ysrq"></el-table-column>
        <el-table-column label="运送品种" prop="j_yspz"></el-table-column>
        <el-table-column label="运送数量" prop="j_yssl"></el-table-column>
        <el-table-column label="联系人" prop="j_lxr"></el-table-column>
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-tooltip effect="dark" content="管理" placement="top-start">
              <el-button
                @click="getInfo(scope.row)"
                type="primary"
                icon="el-icon-s-tools"
              ></el-button>
            </el-tooltip> -->
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click="delInfo(scope.row.id)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>

      <!-- 表单区 -->
      <el-drawer
        :title="title"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <!-- 下拉框 -->
          <el-form-item label="经营单位名称" prop="j_jydwmc">
            <el-input
              v-model="addFormInfo.j_jydwmc"
              placeholder="例如：xxx公司"
            ></el-input>
          </el-form-item>
          <el-form-item label="运输单位" prop="j_ysdw">
            <el-input
              v-model="addFormInfo.j_ysdw"
              placeholder="例如：xxx公司"
            ></el-input>
          </el-form-item>

          <el-form-item label="运输日期" prop="j_ysrq">
            <el-date-picker
              value-format="yyyy-M-d"
              format="yyyy 年 M 月 d 日"
              v-model="addFormInfo.j_ysrq"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="起运地" prop="j_qyd">
            <el-input
              v-model="addFormInfo.j_qyd"
              placeholder="例如：xxx"
            ></el-input>
          </el-form-item>
          <el-form-item label="目的地" prop="j_mdd">
            <el-input
              v-model="addFormInfo.j_mdd"
              placeholder="例如：xxx"
            ></el-input>
          </el-form-item>
          <el-form-item label="运送品种" prop="j_yspz">
            <el-input
              v-model="addFormInfo.j_yspz"
              placeholder="例如：极品早霸"
            ></el-input>
          </el-form-item>
          <el-form-item label="运送数量" prop="j_yssl">
            <el-input
              v-model="addFormInfo.j_yssl"
              placeholder="例如：100"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="j_lxr">
            <el-input
              v-model="addFormInfo.j_lxr"
              placeholder="例如：xxx"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
        </div>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "yunshu",
  props: {
    sc_id: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      queryInfo: {
        token: "",
        query: "",
        id: "",
        page: 1,
        pageSize: 8,
      },
      title: "品种信息",
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        j_sc_id: "",
        token: "",
        name: "",
        j_jydwmc: "",
        j_ysdw: "",
        j_qyd: "",
        j_mdd: "",
        j_ysrq: "",
        j_yspz: "",
        j_lxr: "",
        j_yssl: "",
      },
    };
  },
  created() {
    this.queryInfo.id = this.sc_id;
    this.addFormInfo.j_sc_id = this.sc_id;
    this.getDataList();
  },
  // mounted() {
  //   this.queryInfo.id = this.sc_id;
  //   this.getDataList();
  // },
  methods: {
    addInfo() {
      this.addDialogVisible = true;
      this.title = "添加品种信息";
    },
    addFormSubmit() {
      console.log(this.addFormInfo);
      this.addFormInfo.token = this.tokenStr;
      let id = this.addFormInfo.id;
      if (!id) {
        this.$http.post("addSCZZYS", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("添加信息成功");
            this.getDataList();
          }
        });
      } else {
        this.$http.post("addSCZZYS", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("修改信息成功");
            this.getDataList();
          }
        });
      }
      this.addDialogVisible = false;
      // this.getDataList();
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
      this.title = "修改品种信息";
    },
    async delInfo(id) {
      this.addDialogVisible = false;
      const confirmRes = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmRes != "confirm") {
        return this.$message.info("取消删除");
      }
      const res = await this.$http.post("delSCZZYS", {
        token: this.tokenStr,
        id: id,
      });
      if (res.status !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getDataList();
      console.log(res);
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    handleClick() {},
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getSCzzys", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  },
};
</script>

<style>
</style>