<template>
  <div>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="2">
          <el-button type="primary" @click="addInfo">添加杂交制种信息</el-button>
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        fit="true"
        @row-dblclick="edit"
      >
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="生产商名称" prop="j_scsmc"></el-table-column>
        <el-table-column label="生产经营许可证" prop="j_scjyxkz"> </el-table-column>
        <el-table-column label="生产批次" prop="j_scpc"></el-table-column>
        <el-table-column label="原种/亲本" prop="j_yzqb"></el-table-column>
        <el-table-column label="隔离条件" prop="j_gltj"></el-table-column>
        <el-table-column label="花粉扩散" prop="j_hfks"></el-table-column>
        <el-table-column label="雄性不育" prop="j_xxby"></el-table-column>
        <el-table-column label="串粉程度" prop="j_cfcd"></el-table-column>
        <el-table-column label="父母本" prop="j_fmb"></el-table-column>
        <el-table-column label="适时杂交" prop="j_sszj"></el-table-column>
        <el-table-column label="检查部门" prop="j_jcbm"></el-table-column>
         <el-table-column label="检验结论" prop="j_jyjl"></el-table-column>
        <el-table-column label="负责人" prop="j_fzr"></el-table-column>
        
        
      
        <!-- <el-table-column label="分装" prop="j_fengzhuang"></el-table-column> -->
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click="delInfo(scope.row.id)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>

      <el-drawer
        :title="title"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <!-- 下拉框 -->
          <el-form-item label="生产商名称" prop="j_scsmc">
            <el-input
              v-model="addFormInfo.j_scsmc"
              placeholder="例如：无"
            ></el-input>
          </el-form-item>
          <el-form-item label="生产经营许可证" prop="j_scjyxkz">
            <el-input
              v-model="addFormInfo.j_scjyxkz"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="生产批次" prop="j_scpc">
            <el-input
              v-model="addFormInfo.j_scpc"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="原种/亲本" prop="j_yzqb">
              <!-- <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="addFormInfo.j_shrq" style="width: 100%;"></el-date-picker> -->
            <el-input
              v-model="addFormInfo.j_yzqb"
              placeholder=""
            ></el-input>
          </el-form-item>
           <el-form-item label="隔离条件" prop="j_gltj">
            <el-input
              v-model="addFormInfo.j_gltj"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="花粉扩散" prop="j_hfks">
            <el-input
              v-model="addFormInfo.j_hfks"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="雄性不育" prop="j_xxby">
              <!-- <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="addFormInfo.j_shijian" style="width: 100%;"></el-date-picker> -->
            <el-input
              v-model="addFormInfo.j_xxby"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="串粉程度" prop="j_cfcd">
            <el-input
              v-model="addFormInfo.j_cfcd"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="父母本" prop="j_fmb">
            <el-input
              v-model="addFormInfo.j_fmb"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="适时杂交" prop="j_sszj">
            <el-input
              v-model="addFormInfo.j_sszj"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="检查部门" prop="j_jcbm">
            <el-input
              v-model="addFormInfo.j_jcbm"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="检验结论" prop="j_jyjl">
            <el-input
              v-model="addFormInfo.j_jyjl"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="j_fzr">
            <el-input
              v-model="addFormInfo.j_fzr"
              placeholder=""
            ></el-input>
          </el-form-item>
         
         
        </el-form>
        <div class="drawer-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
        </div>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "jiagong",
  props: {
    sc_id: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      queryInfo: {
        token: "",
        query: "",
        id: "",
        page: 1,
        pageSize: 8,
      },
      title: "品种信息",
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        token: "",
        j_scsmc: "",
        j_scjyxkz: "",
        j_scpc: "",
        j_yzqb: "",
        j_gltj: "",
        j_hfks:"",
        j_xxby:"",
        j_cfcd:"",
        j_fmb:"",
        j_sszj:"",
        j_jcbm:"",
         j_jyjl:"",
        j_fzr: "",
        
        
        
      },
    };
  },
  created() {
    this.queryInfo.id = this.sc_id;
    this.addFormInfo.j_sc_id = this.sc_id;
    this.getDataList();
  },
  methods: {
    addInfo() {
      this.addDialogVisible = true;
      this.title = "添加品种信息";
    },
    addFormSubmit() {
      console.log(this.addFormInfo);
      this.addFormInfo.token = this.tokenStr;
      let id = this.addFormInfo.id;
      if (!id) {
        this.$http.post("addSCZJZZ", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("添加信息成功");
            this.getDataList();
          }
        });
      } else {
        this.$http.post("addSCZJZZ", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("修改信息成功");
            this.getDataList();
          }
        });
      }
      this.addDialogVisible = false;
      // this.getDataList();
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
      this.title = "修改品种信息";
    },
    async delInfo(id) {
      this.addDialogVisible = false;
      const confirmRes = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmRes != "confirm") {
        return this.$message.info("取消删除");
      }
      const res = await this.$http.post("delSCZJZZ", {
        token: this.tokenStr,
        id: id,
      });
      if (res.status !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getDataList();
      console.log(res);
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    handleClick() {},
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getSCZJZZ", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  },
};
</script>

<style>
</style>