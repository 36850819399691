<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-page-header @back="goBack" content="种子生产管理"> </el-page-header>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- -------------------------------基本信息模块开始------------------------------------------------ -->
        <el-tab-pane label="基本信息" name="first">
          <el-card class="box-card">
            <!-- 搜索与添加区域 -->
            <el-row :gutter="10">
              <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
              <el-col :span="2">
                <el-button type="primary" @click="addInfo"
                  >添加基本信息</el-button
                >
              </el-col>
            </el-row>
            <!-- 数据列表区域 -->
            <el-table
              :data="tableData"
              @row-dblclick="edit"
              :fit="true"
              style="width: 100%"
            >
              <el-table-column label="编号" type="index"></el-table-column>
              <el-table-column label="经营单位" prop="j_qygk"></el-table-column>
              <el-table-column label="资质证件" prop="j_zzzj"></el-table-column>
              <el-table-column label="分支机构" prop="j_fzjg"></el-table-column>

              <el-table-column label="商标信息" prop="j_sbxx"></el-table-column>
              <el-table-column label="审定证书" prop="j_sdzs"></el-table-column>
              <el-table-column label="登记" prop="j_dengji"></el-table-column>
              <el-table-column label="委托代繁" prop="j_wtdf"></el-table-column>
              <el-table-column label="委托合同" prop="j_wtht"></el-table-column>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!-- <el-tooltip effect="dark" content="管理" placement="top-start">
              <el-button
                @click="getInfo(scope.row)"
                type="primary"
                icon="el-icon-s-tools"
              ></el-button>
            </el-tooltip> -->
                  <el-tooltip effect="dark" content="删除" placement="top-end">
                    <el-button
                      type="warning"
                      @click="delInfo(scope.row.id)"
                      icon="el-icon-delete"
                    ></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              background
              layout="prev, pager, next,total"
              :total="total"
              :page-size="queryInfo.pageSize"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
            <el-drawer
              :title="title"
              :visible.sync="addDialogVisible"
              width="40%"
              @closed="closedialog"
            >
              <!-- 表单区 -->
              <el-form
                size="medium"
                ref="addFormRef"
                :model="addFormInfo"
                label-width="120px"
              >
                <!-- 下拉框 -->
                <el-form-item label="经营单位" prop="j_qygk">
                  <el-input
                    v-model="addFormInfo.j_qygk"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="资质证件" prop="j_zzzj">
                  <el-input
                    v-model="addFormInfo.j_zzzj"
                    placeholder=""
                  ></el-input>
                </el-form-item>

                <el-form-item label="分支机构" prop="j_fzjg">
                  <el-input
                    v-model="addFormInfo.j_fzjg"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="商标信息" prop="j_sbxx">
                  <el-input
                    v-model="addFormInfo.j_sbxx"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="审定证书" prop="j_sdzs">
                  <el-input
                    v-model="addFormInfo.j_sdzs"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="登记" prop="j_dengji">
                  <el-input
                    v-model="addFormInfo.j_dengji"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="委托代繁" prop="j_wtdf">
                  <el-input
                    v-model="addFormInfo.j_wtdf"
                    placeholder=""
                  ></el-input>
                </el-form-item>
                <el-form-item label="委托合同" prop="j_wtht">
                  <el-input
                    v-model="addFormInfo.j_wtht"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="drawer-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addFormSubmit"
                  >确 定</el-button
                >
              </div>
            </el-drawer>
          </el-card>
        </el-tab-pane>
        <!-- --------------------基本信息模块结束 -->
        <el-tab-pane label="基地信息" name="tab1"
          ><baseinfo :sc_id="queryInfo.id" :key="tab1" /></el-tab-pane>
        <el-tab-pane label="投入品" name="tab2"
          ><trp :sc_id="queryInfo.id" :key="tab2" />
        </el-tab-pane>
        <el-tab-pane label="繁重计划" name="tab3"
          ><fzjh :sc_id="queryInfo.id" :key="tab3" />
        </el-tab-pane>
        <el-tab-pane label="生产档案" name="tab4"
          ><scda :sc_id="queryInfo.id" :key="tab4" />
        </el-tab-pane>
        <el-tab-pane label="杂交制种" name="tab5"
          ><zjzz :sc_id="queryInfo.id" :key="tab5" />
        </el-tab-pane>
        
        <el-tab-pane label="常规种种子田检验" name="tab7"
          ><cgz :sc_id="queryInfo.id" :key="tab7" />
        </el-tab-pane>
        
        <el-tab-pane label="加工包装" name="second"
          ><jiagong :sc_id="queryInfo.id" :key="second"></jiagong
        ></el-tab-pane>
        <el-tab-pane label="种子运输" name="three"
          ><yunshu :sc_id="queryInfo.id" :key="three"></yunshu
        ></el-tab-pane>
        <el-tab-pane label="营销信息" name="four"
          ><scyx :sc_id="queryInfo.id" :key="four"></scyx
        ></el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import yxmanage from "./yxmanage.vue";
import scyx from "./scyx.vue";
import jiagong from "./jiagong.vue";
import yunshu from "./yunshu.vue";
import baseinfo from "./shengchan/baseinfo.vue"
import trp from "./shengchan/trp.vue"
import fzjh from "./shengchan/fzjh.vue"
import scda from "./shengchan/scda.vue"
import zjzz from "./shengchan/zjzz.vue"
import cgz from "./shengchan/cgz.vue"
export default {
  components: {
    yxmanage: yxmanage,
    scyx: scyx,
    jiagong: jiagong,
    yunshu: yunshu,
    baseinfo:baseinfo,
    trp:trp,
    fzjh:fzjh,
    scda:scda,
    zjzz:zjzz,
    cgz:cgz,
  },
  data() {
    return {
      title: "品种信息",
      second: "",
      four: "",
      three: "",
      tab1:"",
      tab2:"",
      tab3:"",
      tab4:"",
      tab5:"",
      tab6:"",
      tab7:"",
      activeName: "first",
      queryInfo: {
        token: "",
        query: "",
        id: "",
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        j_sc_id: "",
        token: "",
        j_qygk: "",
        j_zzzj: "",
        j_fzjg: "",
        j_fzr: "",
      },
    };
  },
  mounted() {
    // console.log(this.queryInfo.j_sc_id);
  },
  created() {
    console.log(this.$route.query.id);
    this.addFormInfo.j_sc_id = this.$route.query.id;
    this.queryInfo.id = this.$route.query.id;
    this.tokenStr = window.sessionStorage.getItem("token");
    this.getDataList();
  },
  methods: {
    addInfo() {
      this.addDialogVisible = true;
      this.title = "添加品种信息";
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
      this.title = "修改品种信息";
    },
    async delInfo(id) {
      this.addDialogVisible = false;
      const confirmRes = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmRes != "confirm") {
        return this.$message.info("取消删除");
      }
      const res = await this.$http.post("delSCJBXX", {
        token: this.tokenStr,
        id: id,
      });
      if (res.status !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getDataList();
      console.log(res);
    },
    goBack() {
      this.$router.push("/company/shengchan");
    },
    handleClick(tab) {
      console.log(tab.name);
      if (tab.name == "second") {
        this.second = new Date().getTime();
      } else if (tab.name == "four") {
        this.four = new Date().getTime();
      } else if (tab.name == "three") {
        this.three = new Date().getTime();
      }
    },
    addFormSubmit() {
      console.log(this.addFormInfo);
      this.addFormInfo.token = this.tokenStr;
      let id = this.addFormInfo.id;
      if (!id) {
        this.$http.post("addSCJBXX", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("添加信息成功");
            this.getDataList();
          }
        });
      } else {
        this.$http.post("addSCJBXX", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("修改信息成功");
            this.getDataList();
          }
        });
      }
      this.addDialogVisible = false;
      // this.getDataList();
    },
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getSCJBXX", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  },
};
</script>

<style>
.drawer-footer {
  text-align: center;
  width: 100%;
}
</style>