<template>
  <div>
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="2">
          <el-button type="primary" @click="addInfo">添加投入品信息</el-button>
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        fit="true"
        @row-dblclick="edit"
      >
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="使用农资类别" prop="j_nzlb"></el-table-column>
        <el-table-column label="农资名称" prop="j_nzmc"> </el-table-column>
        <el-table-column label="品牌" prop="j_pinpai"></el-table-column>
        <el-table-column label="规格" prop="j_guige"></el-table-column>
        <el-table-column label="编号" prop="j_bianhao"></el-table-column>
        <el-table-column label="使用投入品农资名称" prop="j_trpmc"></el-table-column>
        <el-table-column label="数量" prop="j_shuliang"></el-table-column>
        
        
      
        <!-- <el-table-column label="分装" prop="j_fengzhuang"></el-table-column> -->
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click="delInfo(scope.row.id)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>

      <el-drawer
        :title="title"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <!-- 下拉框 -->
          <el-form-item label="使用农资类别" prop="j_nzlb">
            <el-input
              v-model="addFormInfo.j_nzlb"
              placeholder="例如：无"
            ></el-input>
          </el-form-item>
          <el-form-item label="农资名称" prop="j_nzmc">
            <el-input
              v-model="addFormInfo.j_nzmc"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌" prop="j_pinpai">
            <el-input
              v-model="addFormInfo.j_pinpai"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="j_guige">
            <el-input
              v-model="addFormInfo.j_guige"
              placeholder=""
            ></el-input>
          </el-form-item>
           <el-form-item label="编号" prop="j_bianhao">
            <el-input
              v-model="addFormInfo.j_bianhao"
              placeholder="例如：004"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用农资名称" prop="j_trpmc">
            <el-input
              v-model="addFormInfo.j_trpmc"
              placeholder="例如："
            ></el-input>
          </el-form-item>
          <el-form-item label="数量" prop="j_shuliang">
            <el-input
              v-model="addFormInfo.j_shuliang"
              placeholder=""
            ></el-input>
          </el-form-item>
         
         
        </el-form>
        <div class="drawer-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
        </div>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "jiagong",
  props: {
    sc_id: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      queryInfo: {
        token: "",
        query: "",
        id: "",
        page: 1,
        pageSize: 8,
      },
      title: "品种信息",
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        id: "",
        token: "",
        j_nzlb: "",
        j_nzmc: "",
        j_pinpai: "",
        j_guige: "",
        j_bianhao: "",
        j_trpmc:"",
        j_shuliang: "",
        
        
        
      },
    };
  },
  created() {
    this.queryInfo.id = this.sc_id;
    this.addFormInfo.j_sc_id = this.sc_id;
    this.getDataList();
  },
  methods: {
    addInfo() {
      this.addDialogVisible = true;
      this.title = "添加品种信息";
    },
    addFormSubmit() {
      console.log(this.addFormInfo);
      this.addFormInfo.token = this.tokenStr;
      let id = this.addFormInfo.id;
      if (!id) {
        this.$http.post("addSCTrp", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("添加信息成功");
            this.getDataList();
          }
        });
      } else {
        this.$http.post("addSCTrp", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("修改信息成功");
            this.getDataList();
          }
        });
      }
      this.addDialogVisible = false;
      // this.getDataList();
    },
    edit(row, event, column) {
      console.log(row);
      let dataRow = JSON.parse(JSON.stringify(row));
      this.addFormInfo = dataRow;
      console.log(this.addFormInfo);
      this.addDialogVisible = true;
      this.title = "修改品种信息";
    },
    async delInfo(id) {
      this.addDialogVisible = false;
      const confirmRes = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmRes != "confirm") {
        return this.$message.info("取消删除");
      }
      const res = await this.$http.post("delSCTRP", {
        token: this.tokenStr,
        id: id,
      });
      if (res.status !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getDataList();
      console.log(res);
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    handleClick() {},
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getSCTRP", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
    },
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
  },
};
</script>

<style>
</style>